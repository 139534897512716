import $ph from '@/plugins/phoenix';
import { IPhoenixModule } from '@/plugins/phoenix/library';
import { ILink, IView, MenuItem } from '@/plugins/phoenix/types';
import { wallet } from '@/plugins/store';
import Vue from 'vue';
import { WalletmelonAccess } from '../session/types';
import { prepareObject } from '../system';
import { CURRENCY_DEFAULT } from './store';
import { IAccount, ICurrency, ICustomer, IOperationFull, IProcessingRequest, ITransaction, OperTypeModes } from './types';

// Glossaries

// Widgets
Vue.component('dashboard-widget-customer', () => import(/* webpackChunkName: "widgets" */ './components/dashboard-widget-customer.vue'))
Vue.component('dashboard-widget-balance', () => import(/* webpackChunkName: "widgets" */ './components/dashboard-widget-balance.vue'))
Vue.component('dashboard-widget-transactions', () => import(/* webpackChunkName: "widgets" */ './components/dashboard-widget-transactions.vue'))
Vue.component('dashboard-widget-accounts', () => import(/* webpackChunkName: "widgets" */ './components/dashboard-widget-accounts.vue'))
Vue.component('dashboard-graph-portfolio', () => import(/* webpackChunkName: "widgets" */ './components/dashboard-graph-portfolio.vue'))

// Dialogs
Vue.component('IdentDialog', () => import(/* webpackChunkName: "dashboard" */ './dialogs/IdentDialog.vue'))
Vue.component('EmailChangeDialog', () => import(/* webpackChunkName: "dashboard" */ './dialogs/EmailChangeDialog.vue'))
Vue.component('SecondFactorDialog', () => import(/* webpackChunkName: "dashboard" */ './dialogs/SecondFactorDialog.vue'))
Vue.component('OperationDialog', () => import(/* webpackChunkName: "dashboard" */ './dialogs/OperationDialog.vue'))
Vue.component('TransactionDialog', () => import(/* webpackChunkName: "dashboard" */ './dialogs/TransactionDialog.vue'))
Vue.component('QuestionnaireDialog', () => import(/* webpackChunkName: "dashboard" */ './dialogs/QuestionnaireDialog.vue'))
Vue.component('POSDialog', () => import(/* webpackChunkName: "dashboard" */ './dialogs/POSDialog.vue'))

// Components
Vue.component('account-card', () => import(/* webpackChunkName: "dashboard" */ './components/account-card.vue'))
Vue.component('dashboard-header', () => import(/* webpackChunkName: "dashboard" */ './components/dashboard-header.vue'))
Vue.component('dashboard-portfolio', () => import(/* webpackChunkName: "dashboard" */ './components/dashboard-portfolio.vue'))
Vue.component('dashboard-item', () => import(/* webpackChunkName: "dashboard" */ './components/dashboard-item.vue'))
Vue.component('transactions-list', () => import(/* webpackChunkName: "dashboard" */ './components/transactions-list.vue'))
Vue.component('transaction-amount', () => import(/* webpackChunkName: "dashboard" */ './components/transaction-amount.vue'))
Vue.component('transaction-state', () => import(/* webpackChunkName: "dashboard" */ './components/transaction-state.vue'))
Vue.component('transaction-description', () => import(/* webpackChunkName: "dashboard" */ './components/transaction-description.vue'))
Vue.component('statement', () => import(/* webpackChunkName: "dashboard" */ './components/statement.vue'))
Vue.component('payment-method', () => import(/* webpackChunkName: "dashboard" */ './components/payment-method.vue'))
Vue.component('payment-methods-list', () => import(/* webpackChunkName: "dashboard" */ './components/payment-methods-list.vue'))
Vue.component('payment-method-new', () => import(/* webpackChunkName: "dashboard" */ './components/payment-method-new.vue'))

Vue.component('banner-ident', () => import(/* webpackChunkName: "dashboard" */ './components/banner-ident.vue'))
Vue.component('banner-2fa', () => import(/* webpackChunkName: "dashboard" */ './components/banner-2fa.vue'))
Vue.component('banner-quest', () => import(/* webpackChunkName: "dashboard" */ './components/banner-quest.vue'))

Vue.component('v-customer-quest', () => import(/* webpackChunkName: "dashboard" */ './components/v-customer-quest.vue'))

let moduleWallet: IPhoenixModule = {
  name(): string {
    return 'wallet'
  },

  routes(): Array<ILink> {
    return [
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/Dashboard.vue'),
        name: 'Dashboard',
        path: '/',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/Accounts.vue'),
        name: 'Accounts',
        path: '/accounts',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/Prices.vue'),
        name: 'Prices',
        path: '/prices',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/Transactions.vue'),
        name: 'Transactions',
        path: '/transactions',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/StatementView.vue'),
        name: 'Statement',
        path: '/statement',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './reports/AccountsStatementPrint.vue'),
        name: 'StatementPrint',
        path: '/statement/print',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/ProcessingView.vue'),
        name: 'Requests',
        path: '/requests',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/Settings.vue'),
        name: 'Settings',
        path: '/settings',
        access: WalletmelonAccess.ANY,
        props: false,
      },
      {
        component: () => import(/* webpackChunkName: "wallet" */ './views/Reports.vue'),
        name: 'Reports',
        path: '/reports',
        access: WalletmelonAccess.ANY,
        props: false,
      },
    ];
  },

  userMenu(): Array<MenuItem> {
    return [];
  },

  appMenu(): Array<MenuItem> {
    return [];
  },

  toolbar(): Array<MenuItem> {
    return [];
  },

  reports(): Array<IView> {
    return [];
  },

  async init(): Promise<any> {
  },
}

export default moduleWallet;

// -------------------------------------------------------------------------------------------------------------------------------------------------------
// -- HELPERS
// -------------------------------------------------------------------------------------------------------------------------------------------------------

export function currency(code: string): ICurrency {
  return wallet.currIndex[code] || CURRENCY_DEFAULT()
}

export function prepareCustomerNumber(number: string | null): string {
  if (!number) {
    return '';
  } else if (number === 'SYSTEM') {
    return number;
  } else {
    return number.substring(0, 3) +
      '-' +
      number.substring(3, 5) +
      '-' +
      number.substring(5, 8)
  }
}

export function prepareCustomer(item: ICustomer): ICustomer {
  item.customerNumberPublic = prepareCustomerNumber(item.customerNumber)
  return prepareObject(item);
}

export function prepareAccountNumber(number: string | null): string {
  return number ? (number.substring(0, 3) +
    '-' +
    number.substring(3, 8) +
    '-' +
    number.substring(8, 12)) : '';
}

export function prepareAccount(item: IAccount): IAccount {
  if (item) {
    item.accountNumberPublic = prepareAccountNumber(item.accountNumber)
    item.customerNumberPublic = prepareCustomerNumber(item.customerNumber)
    return prepareObject(item);
  } else {
    return item;
  }
}

export function prepareTransaction(item: ITransaction, currencies: Record<string, ICurrency>): ITransaction {
  item.tranAccountDtPublic = prepareAccountNumber(item.tranAccountDtNumber)
  item.tranAccountCtPublic = prepareAccountNumber(item.tranAccountCtNumber)
  item.currency = currencies[item.tranDtCurrency] || $ph.clone(CURRENCY_DEFAULT);
  return item;
}

export function prepareOperation(customer: string, currencies: Record<string, ICurrency>, item: IOperationFull): IOperationFull {
  if (item.operMode === OperTypeModes.Transfer) {
    if (customer === item.operInCustomer && customer !== item.operOutCustomer) {
      item.operMode = OperTypeModes.TransferIn
    } else if (customer === item.operOutCustomer && customer !== item.operInCustomer) {
      item.operMode = OperTypeModes.TransferOut
    }
  }

  let currIn: ICurrency = currencies[item.operInCurrency];
  let currOut: ICurrency = currencies[item.operOutCurrency];

  currIn = currIn || CURRENCY_DEFAULT;
  currOut = currOut || CURRENCY_DEFAULT;

  item.operInAmountPublic = $ph.format(item.operInAmount, { dec: currIn.currencyPrecision })
  item.operOutAmountPublic = $ph.format(item.operOutAmount, { dec: currOut.currencyPrecision })
  item.operFeeAmountPublic = $ph.format(item.operFeeAmount, { dec: currOut.currencyPrecision })

  item.operInAccountPublic = prepareAccountNumber(item.operInAccount)
  item.operOutAccountPublic = prepareAccountNumber(item.operOutAccount)

  return item;
}

export function prepareProcessing(currencies: Record<string, ICurrency>, item: IProcessingRequest): IProcessingRequest {
  let curr: ICurrency = currencies[item.requestCurrency];
  let currProcess: ICurrency = currencies[item.requestCurrencyProcess];

  item.currency = curr || CURRENCY_DEFAULT
  item.processingCurrency = currProcess || CURRENCY_DEFAULT

  return item;
}
