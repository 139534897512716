import { StringGlossary } from '@/plugins/phoenix/glossaries';
import { DEF_STRING_ITEM } from '../store';
import { ProcessStates } from '../types';

const glossary: StringGlossary = new StringGlossary('ProcessStates',
  [
    { code: ProcessStates.CancelFail, label: 'globals.lists.process.' + ProcessStates.CancelFail, icon: 'fa-exclamation-triangle', color: 'error', textColor: null },
    { code: ProcessStates.Canceled, label: 'globals.lists.process.' + ProcessStates.Canceled, icon: 'fa-times', color: 'disabled', textColor: null },
    { code: ProcessStates.Cancelling, label: 'globals.lists.process.' + ProcessStates.Cancelling, icon: 'fa-cogs', color: 'primary', textColor: null },
    { code: ProcessStates.Created, label: 'globals.lists.process.' + ProcessStates.Created, icon: 'fa-file', color: 'info', textColor: null },
    { code: ProcessStates.Done, label: 'globals.lists.process.' + ProcessStates.Done, icon: 'fa-check-circle', color: 'success', textColor: null },
    { code: ProcessStates.Failed, label: 'globals.lists.process.' + ProcessStates.Failed, icon: 'fa-exclamation-circle', color: 'error', textColor: null },
    { code: ProcessStates.Preparing, label: 'globals.lists.process.' + ProcessStates.Preparing, icon: 'fa-tools', color: 'primary', textColor: null },
    { code: ProcessStates.Processing, label: 'globals.lists.process.' + ProcessStates.Processing, icon: 'fa-cogs', color: 'primary', textColor: null },
    { code: ProcessStates.Suspended, label: 'globals.lists.process.' + ProcessStates.Suspended, icon: 'fa-hand-paper', color: 'warning', textColor: null },
    { code: ProcessStates.WaitForProcess, label: 'globals.lists.process.' + ProcessStates.WaitForProcess, icon: 'fa-clock', color: 'primary', textColor: null },
  ],
  DEF_STRING_ITEM,
);

export default glossary;
