import { GlossaryItemCode, IGlossaryItem } from '@/plugins/phoenix/glossaries';

export enum IScreenSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export interface ICountry {
  countryCode: string
  countryCodeA3: string
  countryName: string
  countryOnboard: boolean
}

export interface IProduct {
  productCode: string
  productName: string
  productCurrencyType: string
}

export enum FieldStyles {
  outlined = 'outlined',
  solo = 'solo',
  inverted = 'inverted',
  filled = 'filled',
  regular = 'regular',
}

export enum FieldModes {
  text = 'string',
  numeric = 'number',
  date = 'date',
  datepicker = 'date-picker',
  datetime = 'datetime',
  calendar = 'calendar',
}

export enum CodeModes {
  list = 'list',
  selection = 'selection',
  both = 'both',
  none = 'none',
}

export enum CodeSyles {
  chip = 'chip',
  icon = 'icon',
  text = 'text',
}

export enum LabelStyles {
  list = 'list',
  selection = 'selection',
  both = 'both',
  none = 'none',
}

export interface ITableStyle {
  rowsTableOptions: Array<number>
}
export interface ITableHeader {
  text: string
  value: string
  align?: 'start' | 'center' | 'end'
  sortable?: boolean
  filterable?: boolean
  groupable?: boolean
  divider?: boolean
  class?: string | string[]
  width?: string | number
  filter?: (value: any, search: string, item: any) => boolean
  sort?: (a: any, b: any) => number
}

export interface ITableOptions {
  itemsPerPage: number
  page: number
  sortBy: Array<string>
  sortDesc: Array<boolean>
}

export enum ProcessStates {
  Any = '?',
  WaitForAccept = 'a',
  None = 'n',
  Created = 'c',
  Preparing = 'r',
  WaitForProcess = 'w',
  Processing = 'p',
  Suspended = 's',
  Cancelling = 'i',
  Canceled = 'l',
  CancelFail = 'e',
  Failed = 'f',
  Done = 'd',
  Committed = 'o',
  PostCommitWarn = 't',
}

export enum EntityStates {
  Active = 'a',
  Blocked = 'b',
  Initialization = 'i',
  Closed = 'c',
}

export enum FilterFieldType {
  StringField = 'StringField',
  NumberField = 'NumberField',
  NumberRange = 'NumberRange',
  DateField = 'DateField',
  DateRange = 'DateRange',
  CustomerField = 'CustomerField',
  AccountField = 'AccountField',
  Switch = 'Switch',
  List = 'List',
  MultiList = 'MultiList'
}

export interface IFilterField {
  name: string;
  items: Array<IGlossaryItem<GlossaryItemCode>>;
  format: string | null;
  type: FilterFieldType;
  defaultValue: any;
  currentValue: any;
  chipCaption: string;
  code?: string;
  label?: string;
  color?: string;
}

export interface ISearchNode {
  node: string;
  total: number;
}

export interface ISearchBase extends ISearchNode {
  limit: number;
  offset: number;
  nodesInfo: Array<ISearchNode>;
}

export interface ISearchResult<I> extends ISearchBase {
  items: Array<I>;
}

export interface IFilter extends ISearchBase {
  export: boolean;
  append: boolean;
  defaultSearch: string | null;
  sortBy: string | null;
  sortOrder: string | null;
}

export interface IDateRange {
  from: string | null;
  till: string | null;
}

export interface INumberRange {
  from: number | null;
  till: number | null;
}

export interface IDateFilter extends IFilter {
  range: IDateRange;
}

export enum AppWidgetType {
  Form = 'form',
  Action = 'action',
  Dashboard = 'dashboard',
  Processing = 'processing',
  Statements = 'statements',
  Operations = 'operations',
}

export enum AppWidgetDisplay {
  None = 'none',
  Readonly = 'readonly',
  Hidden = 'hidden',
}

export interface IAppWidget {
  widgetName: string
  widgetType: AppWidgetType
  widgetProfileType: string
  widgetDisplaySM: AppWidgetDisplay
  widgetDisplayMD: AppWidgetDisplay
  widgetDisplayLG: AppWidgetDisplay
  widgetRow: number
  widgetOrderSM: number
  widgetOrderMD: number
  widgetOrderLG: number
  widgetSizeSM: number
  widgetSizeMD: number
  widgetSizeLG: number
  widgetSettings: string
  settings: Record<string, any>
}

export interface IAppWidgetRow {
  cols: Array<IAppWidget>
}

export interface IAccountWidgetSettings {
  order: number
  action: string
  operations: Array<String>
  graph: string
  baseAmount: boolean
  holdAmount: boolean
  accountNumber: boolean
  accountName: boolean
  tradingInfo: boolean
  typeIcon: string
  iconColor: string
  backColor: string
  background: string
  style: string
}

export enum ProcessingMethods {
  Deposit = 'c',
  Payout = 'p',
  Refund = 'r',
}

export enum ReportFormats {
  CSV = 'csv',
  PDF = 'pdf',
}
