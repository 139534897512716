import { IGlossary, IStringGlossaryItem, StringGlossary } from '@/plugins/phoenix/glossaries';
import { system } from '@/plugins/store';
import { DEF_STRING_ITEM } from '../store';

export class CountriesGlossary implements IGlossary<string, IStringGlossaryItem> {
  name: string = 'Countries'
  loaded = false
  list: Array<IStringGlossaryItem> = [];

  get map(): Record<string, IStringGlossaryItem> {
    let map: Record<string, IStringGlossaryItem> = {};
    this.items.forEach(item => {
      map[item.code] = item
    });
    return map;
  }

  get items(): IStringGlossaryItem[] {
    if (system.countries.length !== 0 && !this.loaded) {
      this.loaded = true;
      this.reload();
    }
    return this.list
  }

  item(code: string, def: IStringGlossaryItem | null = null): IStringGlossaryItem {
    return this.map[code] || (def || Object.assign({ code }, DEF_STRING_ITEM));
  }

  async reload() {
    system.countries.forEach(country => {
      this.list.push({
        code: country.countryCode,
        label: 'globals.lists.countries.' + country.countryCode,
        icon: null,
        color: country.countryOnboard ? 'primary' : 'error',
        textColor: null,
      });
    })
  }
}

const glossary = new CountriesGlossary();
export default glossary;
