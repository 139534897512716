import { DEF_STRING_ITEM } from '@/modules/system/store';
import $ph from '@/plugins/phoenix';
import { IGlossary, IStringGlossaryItem } from '@/plugins/phoenix/glossaries';
import { system } from '@/plugins/store';

export class LanguagesGlossary implements IGlossary<string, IStringGlossaryItem> {
  name: string = 'Languages'
  loaded = false
  list: Array<IStringGlossaryItem> = [];

  get map(): Record<string, IStringGlossaryItem> {
    let map: Record<string, IStringGlossaryItem> = {};
    this.items.forEach(item => {
      map[item.code] = item
    });
    return map;
  }

  get items(): IStringGlossaryItem[] {
    if (!this.loaded) {
      $ph.languages().forEach(l => {
        this.list.push({
          code: l.code,
          color: 'info',
          icon: null,
          label: l.name,
          textColor: null,
        })
      })
    }

    return this.list
  }

  item(code: string, def: IStringGlossaryItem | null = null): IStringGlossaryItem {
    return this.map[code] || (def || Object.assign({ code }, DEF_STRING_ITEM));
  }

  async reload() {
  }
}

const glossary = new LanguagesGlossary();
export default glossary;
