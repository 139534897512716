import Vue from 'vue'
import Vuex from 'vuex'
import SessionStore from '@/modules/session/store'
import SystemStore from '@/modules/system/store'
import WalletStore from '@/modules/wallet/store'
import CheckoutStore from '@/modules/checkout/store'

import { getModule } from 'vuex-module-decorators'

const debugMode = process.env.VUE_APP_ENV !== 'PROD'

Vue.use(Vuex)
const store = new Vuex.Store({
  strict: debugMode,

  modules: {
    system: SystemStore,
    session: SessionStore,
    wallet: WalletStore,
    checkout: CheckoutStore,
  },
})

export default store
export const system: SystemStore = getModule(SystemStore, store);
export const session: SessionStore = getModule(SessionStore, store);
export const wallet: WalletStore = getModule(WalletStore, store);
export const checkout: CheckoutStore = getModule(CheckoutStore, store);
