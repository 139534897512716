export type GlossaryItemCode = string | number;
export type GlossaryOrder = 'byKey' | 'byValue' | 'none';

export interface IGlossaryItem<T extends GlossaryItemCode> {
  code: T;
  label: string;
  icon: string | null;
  color: string | null;
  textColor: string | null;
  disabled?: boolean;
}

export interface IGlossary<T extends GlossaryItemCode, I extends IGlossaryItem<T>> {
  name: string;
  map: Record<T, I>;
  items: Array<I>;
  reload(): void;
}

export interface IGlossaryList {
  map: Record<string, IGlossary<any, any>>
  items: Array<IGlossary<any, any>>
}

export class Glossary<T extends GlossaryItemCode, I extends IGlossaryItem<T>> implements IGlossary<T, I> {
  name: string;
  map: Record<T, I>;
  items: Array<I>;
  def: I;

  constructor(name: string, items: Array<I>, def: I) {
    this.name = name;
    this.items = items;
    this.def = def;

    let index: any = {};
    for (let item of items) {
      index[item.code] = item;
    }

    this.map = index;
  }

  item(code: T, def: I | null = null): I {
    return this.map[code] || (def || Object.assign({ code }, this.def));
  }

  reload() {
  }
}

export interface IStringGlossaryItem extends IGlossaryItem<string> {
}

export interface INumGlossaryItem extends IGlossaryItem<number> {
}

export class StringGlossary extends Glossary<string, IStringGlossaryItem> {
}

export class NumberGlossary extends Glossary<number, INumGlossaryItem> {
}
