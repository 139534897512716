import $ph from '@/plugins/phoenix'
import $router from '@/plugins/router'
import $vuetify from '@/plugins/vuetify'
import $moment from 'moment'
import { system } from '@/plugins/store';
import { AppError } from '@/plugins/phoenix/library'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IAppEnvironmet, IAuthorisation, INotification, IOTP, IPasswordChange, IRegistration, IUser, IUserCustomer, SessionState, User2FAModes } from './types'
import { ILanguage } from '@/plugins/phoenix/types';
import { ICountry } from '../system/types';
import { ICustomerQuestionnaire } from '../wallet/types';
import settings from '@/plugins/settings';

const LOCAL_STORAGE_THEME_KEY = 'wml-theme-dark';
const LOCAL_STORAGE_LANGUAGE_KEY = 'wml-language';
const LOCAL_STORAGE_API_URL_KEY = 'wml-api-url';

@Module({ name: 'session' })
export default class SessionStore extends VuexModule {
  _instance: string = '';
  _environment: string = '';
  _sessionState: SessionState = SessionState.Loading;
  _error: AppError | null = null;
  _notify: INotification | null = null;
  _onboardCountries: Array<ICountry> = [];
  _questAsk = true;
  _loading = false;

  _user: IUser = {
    userUID: '',
    name: '',
    userFullName: '',
    userLogin: '',
    userPhone: '',
    userEmail: '',
    userLanguage: 'en',
    userHomePage: '/about',
    rowsPerPage: 10,
    userBaseCurrency: settings.company.companyBaseCurrency,
    userDarkTheme: false,
    userLocked: false,
    emailVerified: false,
    askSetup2FA: false,
    checkLogon2FA: false,
    checkOper2FA: false,
    mode2Fa: User2FAModes.None,
    identComplete: false,
    moneySourceVerified: false,
    userStartupStep: 0,
    customerNumber: '',
    personNumber: '',
    userProfileType: '',
    processing: false,
    userLastLogin: new Date(),
    userLastIP: '',
    linkedCustomers: [],
  };

  get instance(): string {
    return this._instance
  }

  get environment(): string {
    return this._environment
  }

  get loading(): boolean {
    return this._loading
  }

  get user(): IUser {
    return this._user
  }

  get currentState(): SessionState {
    return this._sessionState
  }

  get error(): AppError | null {
    return this._error;
  }

  get notification(): INotification | null {
    return this._notify;
  }

  get currentLanguage(): ILanguage {
    const lang = $ph.languages().filter(l => l.code === this.user.userLanguage)
    if (lang && lang.length > 0) {
      return lang[0]
    } else {
      return $ph.currentLanguage()
    }
  }

  get errorMessage(): string {
    if (this._error) {
      const msgKey = 'system.errors.' + this._error.errorCode
      const msg = $ph.i18n(msgKey, system.appName)
      if (msg === msgKey) {
        if (this._error.errorCode.startsWith('HTTP')) {
          return $ph.i18n('system.errors.HTTP', system.appName)
        } else if (this._error.errorCode === 'SYS006') {
          return $ph.i18n('system.errors.LGN')
        } else {
          return $ph.i18n('system.errors.SYS', system.appName)
        }
      } else {
        return msg;
      }
    } else {
      return '';
    }
  }

  get errorTimeout(): number {
    if (this._error) {
      return this._error.timeout || 5000;
    } else {
      return 5000;
    }
  }

  get onboardCountries(): Array<ICountry> {
    return this._onboardCountries;
  }

  get questAsk(): boolean {
    return this._questAsk
  }

  // ---------------------------------------------------------------------- MUTATIONS

  @Mutation
  setUser(data: any) {
    this._user.name = data.name
    this._user.userLogin = data.userLogin
    this._user.userPhone = data.userPhone
    this._user.userEmail = data.userEmail
    this._user.userHomePage = data.userHomePage || '/'
    this._user.rowsPerPage = data.rowsPerPage
    this._user.userBaseCurrency = data.userBaseCurrency
    this._user.userDarkTheme = data.userDarkTheme
    this._user.checkLogon2FA = data.checkLogon2FA
    this._user.checkOper2FA = data.checkOper2FA
    this._user.askSetup2FA = data.askSetup2FA
    this._user.emailVerified = data.emailVerified
    this._user.identComplete = data.identComplete
    this._user.moneySourceVerified = data.moneySourceVerified
    this._user.userStartupStep = data.userStartupStep
    this._user.userProfileType = data.userProfileType
    this._user.userLastLogin = data.userLastLogin
    this._user.userLastIP = data.userLastIP
    this._user.mode2Fa = data.mode2Fa
    this._user.linkedCustomers = data.linkedCustomers

    data.accesses['Any'] = true
    $ph.setup({ accesses: data.accesses })
    $vuetify.framework.theme.dark = settings.company.companyUISettings.theme.useThemes ? data.userDarkTheme : settings.company.companyUISettings.theme.defaultTheme === 'dark'

    system.setTableRows(data.rowsPerPage);
  }

  @Mutation
  setEnvironment(data: any) {
    if (data) {
      this._environment = data.serverEnvironment || this._environment
      this._instance = data.serverInstance || this._instance
    }
  }

  @Mutation
  setLoading(value: boolean) {
    this._loading = value;
  }

  @Mutation
  setCurrentState(value: SessionState) {
    this._sessionState = value;
  }

  @Mutation
  setDarkTheme(value: boolean) {
    value = settings.company.companyUISettings.theme.useThemes ? value : settings.company.companyUISettings.theme.defaultTheme === 'dark'
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, value ? 'yes' : 'no')
    $vuetify.framework.theme.dark = value;
    this._user.userDarkTheme = value;
  }

  @Mutation
  setLanguage(value: string) {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, value)
    $vuetify.framework.lang.current = value;
    $moment.locale(value)
    $ph.setup({ localization: { currentLanguage: value } })
    this._user.userLanguage = value;
  }

  @Mutation
  setLanguageTemporary(value: string) {
    $vuetify.framework.lang.current = value;
    $moment.locale(value)
    this._user.userLanguage = value;
  }

  @Mutation
  setError(value: AppError | null) {
    this._error = value;
  }

  @Mutation
  showNotification(value: INotification | null) {
    this._notify = value;
  }

  @Mutation
  setOnboardCountries(value: Array<ICountry>) {
    this._onboardCountries = (value || []).filter(c => c.countryOnboard);
  }

  @Mutation
  setUserIdentComplete(value: boolean) {
    this._user.identComplete = value
  }

  @Mutation
  setUserAskSetup2FA(value: boolean) {
    this._user.askSetup2FA = value
  }

  @Mutation
  setQuestAsk(value: boolean) {
    this._questAsk = value
  }

  // ---------------------------------------------------------------------- ACTIONS

  @Action({ rawError: true })
  async loadEnvironment() {
    $ph.info('Starting ' + process.env.APP_NAME + '...')

    this.setDarkTheme(localStorage.getItem(LOCAL_STORAGE_THEME_KEY) === 'yes')

    let language: any = $router.currentRoute.query['lang'];
    if (language) {
      this.setLanguageTemporary(language);
    } else {
      language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY)
      language = language || (navigator.language || 'en-US')
      if (language.length > 2) {
        language = language.substring(0, 2)
      }

      if (settings.company.companyUISettings.languages.some(l => l === language)) {
        this.setLanguage(language)
      } else {
        this.setLanguage(settings.company.companyUISettings.languages[0])
      }
    }

    $ph.setup({
      router: $router,
      http: {
        baseUrl: localStorage.getItem(LOCAL_STORAGE_API_URL_KEY),
      },
    });

    system.updateSystem()
    $ph.info('Started ' + process.env.APP_NAME + ' using ' + language)
  }

  @Action({ rawError: true })
  async changeDarkTheme(dark: boolean) {
    this.setDarkTheme(dark)
  }

  @Action({ rawError: true })
  async changeLanguage(language: string) {
    await $ph.post('/users/current/language', language, {}, 'text/plain')
    this.setLanguage(language)
    location.reload()
  }

  @Action({ rawError: true })
  async changeBaseCurrency(currency: string) {
    this.setLoading(true)
    try {
      await $ph.post('/users/current/base-currency', currency, {}, 'text/plain')
      location.reload()
    } catch (err) {
      this.setLoading(false)
      throw err
    }
  }

  @Action({ rawError: true })
  async changeLanguageNoSession(language: string) {
    this.setLanguage(language)
  }

  @Action({ rawError: true })
  async loadUser() {
    $ph.info('Try to load user....')
    try {
      let data = await $ph.get('/users/current/session')
      this.setUser(data)
      this.setLanguage(data.userLanguage)
      $ph.setup({ http: { csrfToken: data.securityToken } });
      $ph.info('User data loaded')
    } catch (err) {
      const error: any = err
      if (error.errorCode === 'HTTP404') {
        error.errorCode = 'SYS004'
      }
      throw error;
    }
  }

  @Action({ rawError: true })
  async loadSettings() {
    $ph.log('Loading settings...')

    let data: IAppEnvironmet = await $ph.get('/application/data')

    this.setEnvironment(data)
    $ph.applyLocalizations(data.translates)

    system.setCountries(data.glossaries.countries)
    system.setProducts(data.glossaries.products)
    system.setWidgets(data.widgets)

    await $ph.init()

    system.updateSystem()
  }

  @Action({ rawError: true })
  async balancer(login: string) {
    $ph.setup({ http: { baseUrl: '' } })

    let userHash = btoa(login)
    let path = await $ph.get('/balancer/users/' + userHash)

    if (path) {
      localStorage.setItem(LOCAL_STORAGE_API_URL_KEY, path)
      $ph.setup({ http: { baseUrl: path } })
    } else {
      throw new AppError('SYS001', 'Balancer not accessible!')
    }
  }

  @Action({ rawError: true })
  async onboard() {
    this.setOnboardCountries(await $ph.get('/application/countries'))
  }

  @Action({ rawError: true })
  async userLogon(auth: IAuthorisation) {
    let token = await $ph.post('/users/session', auth)
    $ph.setup({ http: { csrfToken: token } });
    this.setCurrentState(SessionState.PreAuthorized);
  }

  @Action({ rawError: true })
  async confirmSession() {
    $ph.post('/users/current/session/confirm', {})
  }

  @Action({ rawError: true })
  async userRegister(register: IRegistration) {
    await $ph.post('/users', register)
  }

  @Action({ rawError: true })
  async userOTP(otp: IOTP) {
    await $ph.post('/users/current/session/otp', otp)
  }

  @Action({ rawError: true })
  async userSwitchCustomer(customerNumber: string) {
    this.setLoading(true)
    try {
      await $ph.post('/users/current/session/customer/' + customerNumber, {})
      location.reload()
    } catch (err) {
      this.setLoading(false)
      throw err
    }
  }

  @Action({ rawError: true })
  async userSetPass(payload: IPasswordChange) {
    await $ph.post('/users/current/password', payload)
  }

  @Action({ rawError: true })
  async userLogout() {
    await $ph.delete('/users/current/session')
    this.setCurrentState(SessionState.WaitAuth);
  }

  @Action({ rawError: true })
  async activateEmail() {
    await $ph.post('/verifications/email', {})
  }

  @Action({ rawError: true })
  async checkEmail(code: string) {
    await $ph.post('/verifications/email/check', code)
  }

  @Action({ rawError: true })
  async changeEmail(email: string) {
    await $ph.post('/verifications/email/change', email)
  }

  @Action({ rawError: true })
  async changeEmailCheck(code: string) {
    await $ph.post('/verifications/email/change/check', code)
  }

  @Action({ rawError: true })
  async sendRecoveryEmail(email: string) {
    await $ph.post('/verifications/password', btoa(email))
  }

  @Action({ rawError: true })
  async sendRecoveryOtp(masterKey: string) {
    await $ph.post('/verifications/otp', btoa(masterKey))
  }

  @Action({ rawError: true })
  async generateTerminalKey(number: string): Promise<string> {
    return $ph.post('/customers/terminals/' + number + '/secrets', {})
  }

  @Action({ rawError: true })
  async storeQuestionnaire(quest: ICustomerQuestionnaire): Promise<string> {
    return $ph.post('/customers/questionnaires', quest)
  }

  @Action({ rawError: true })
  async pushError(value: any) {
    let error: AppError | null = null

    if (value) {
      let msg = ''

      if (value.message) {
        msg = value.message
      } else if (value.errorText) {
        msg = value.errorText
      }

      let details = ''

      if (value.details) {
        details = value.details
      } else if (value.errorText) {
        details = value.errorDetails
      }

      let code = ''

      if (value.errorCode) {
        code = value.errorCode
      } else if (value.response) {
        code = 'HTTP' + value.response.status
      } else {
        code = 'SYS001'
      }

      let timeout = 5000

      if (value.timeout) {
        timeout = value.timeout
      }

      let errKey = 'system.error.' + code
      let errMsgLocal = $ph.i18n(errKey)

      if (errKey !== errMsgLocal) {
        msg = errMsgLocal
      }

      if (code === 'SYS004' || code === 'SYS005') {
        if (this.currentState === SessionState.Authorized || this.currentState === SessionState.PreAuthorized) {
          this.setCurrentState(SessionState.WaitAuth);
          window.location.href = window.location.origin
          location.reload()
          return
        }
      }

      error = new AppError(code, msg, details, false, timeout)
      $ph.error(error)
    }

    this.setError(error);
  }

  @Action({ rawError: true })
  async pushHiddenError(value: any) {
    let error: AppError | null = null

    if (value) {
      let msg = ''

      if (value.message) {
        msg = value.message
      } else if (value.errorText) {
        msg = value.errorText
      }

      let details = ''

      if (value.details) {
        details = value.details
      } else if (value.errorText) {
        details = value.errorDetails
      }

      let code = ''

      if (value.errorCode) {
        code = value.errorCode
      } else if (value.response) {
        code = 'HTTP' + value.response.status
      } else {
        code = 'SYS001'
      }

      let errKey = 'system.error.' + code
      let errMsgLocal = $ph.i18n(errKey)

      if (errKey !== errMsgLocal) {
        msg = errMsgLocal
      }

      if (code === 'SYS004' || code === 'SYS005') {
        if (this.currentState === SessionState.Authorized || this.currentState === SessionState.PreAuthorized) {
          this.setCurrentState(SessionState.WaitAuth);
          window.location.href = window.location.origin
          location.reload()
          return
        }
      }

      error = new AppError(code, msg, details, true)
      $ph.error(error)
    }

    this.setError(error);
  }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------
// -- DEFAULTS
// -------------------------------------------------------------------------------------------------------------------------------------------------------

export const USER_DEFAULT: IUser = {
  userUID: null,
  name: '',
  userFullName: '',
  rowsPerPage: 10,
  userBaseCurrency: settings.company.companyBaseCurrency,
  userDarkTheme: false,
  userEmail: '',
  userLanguage: 'ru',
  userHomePage: '/dashboard',
  userLogin: '',
  userPhone: '',
  customerNumber: '',
  personNumber: '',
  userLocked: true,
  processing: false,
  emailVerified: false,
  userProfileType: '',
  askSetup2FA: false,
  checkLogon2FA: false,
  checkOper2FA: false,
  mode2Fa: User2FAModes.None,
  identComplete: false,
  moneySourceVerified: false,
  userStartupStep: 0,
  userLastLogin: new Date(),
  userLastIP: '',
  linkedCustomers: [],
}
