import { DEF_STRING_ITEM } from '@/modules/system/store';
import { IGlossary, IStringGlossaryItem } from '@/plugins/phoenix/glossaries';
import { system } from '@/plugins/store';

export class ProductsGlossary implements IGlossary<string, IStringGlossaryItem> {
  name: string = 'Products'
  loaded = false
  list: Array<IStringGlossaryItem> = [];

  get map(): Record<string, IStringGlossaryItem> {
    let map: Record<string, IStringGlossaryItem> = {};
    this.items.forEach(item => {
      map[item.code] = item
    });
    return map;
  }

  get items(): IStringGlossaryItem[] {
    if (system.products.length !== 0 && !this.loaded) {
      this.loaded = true;
      this.reload();
    }
    return this.list
  }

  item(code: string, def: IStringGlossaryItem | null = null): IStringGlossaryItem {
    return this.map[code] || (def || Object.assign({ code }, DEF_STRING_ITEM));
  }

  async reload() {
    system.products.forEach(product => {
      this.list.push({
        code: product.productCode,
        label: 'globals.lists.products.' + product.productCode,
        icon: null,
        color: 'primary',
        textColor: null,
      });
    })
  }
}

const glossary = new ProductsGlossary();
export default glossary;
