import $ph from '@/plugins/phoenix';
import { INumGlossaryItem, IStringGlossaryItem } from '@/plugins/phoenix/glossaries';
import { ILanguage, MenuItem } from '@/plugins/phoenix/types';
import settings from '@/plugins/settings';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IAppWidget, IScreenSize, ICountry, IProduct, ISearchResult, ITableOptions, ITableStyle, IAppWidgetRow, IAccountWidgetSettings } from './types';

@Module({ name: 'system' })
export default class SystemStore extends VuexModule {
  _appName: string = settings.company.brandName || 'Application';
  _version: string = process.env.APP_VERSION || '0.1.0';

  _screenSize: IScreenSize = IScreenSize.lg;

  _tableRows: number = 15
  _menu: Array<MenuItem> = []
  _languages: Array<ILanguage> = []
  _reportView: boolean = false

  _widgets: Array<IAppWidget> = []
  _dashboardRows: Array<IAppWidgetRow> = []
  _widgetIndex: Record<string, IAppWidget> = {}
  _operationsStyles: Record<string, string> = {}
  _accountsSettings: Record<string, IAccountWidgetSettings> = {}

  // GLOSSARIES DATA
  _countries: Array<ICountry> = []
  _products: Array<IProduct> = []

  get tableRows(): number {
    return this._tableRows
  }

  get version(): string {
    return this._version
  }

  get appName(): string {
    return this._appName
  }

  get menu() {
    return this._menu;
  }

  get languages() {
    return this._languages;
  }

  get countries() {
    return this._countries;
  }

  get products() {
    return this._products;
  }

  get screenSize(): IScreenSize {
    return this._screenSize;
  }

  get widgets() {
    return this._widgets;
  }

  get dashboardRows() {
    return this._dashboardRows;
  }

  get widgetsIndex() {
    return this._widgetIndex;
  }

  get reportView(): boolean {
    return this._reportView
  }

  get tableStyle(): ITableStyle {
    return {
      rowsTableOptions: [15, 50, 100, -1],
    }
  }

  get operStyles(): Record<string, string> {
    return this._operationsStyles
  }

  get accountSettings(): Record<string, IAccountWidgetSettings> {
    return this._accountsSettings
  }

  get showDashboard(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? Boolean(widget.settings.dashboard) : false;
  }

  get showPrices(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? Boolean(widget.settings.prices) : false;
  }

  get showAccounts(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? Boolean(widget.settings.accounts) : false;
  }

  get showTransactions(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? Boolean(widget.settings.transactions) : false;
  }

  get showStatement(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? Boolean(widget.settings.statement) : false;
  }

  get showProcessing(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? Boolean(widget.settings.processing) : false;
  }

  get balanceAccTypes(): Array<string> {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? widget.settings.balanceAccounts || [] : [];
  }

  get balanceCurrencies(): Array<string> {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings ? widget.settings.balanceCurrencies || [] : [];
  }

  get showCheckoutPOS(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings && widget.settings.pos && widget.settings.pos.length > 0 ? widget.settings.pos.some(o => o === 'checkout') : false;
  }

  get showLinkPOS(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings && widget.settings.pos && widget.settings.pos.length > 0 ? widget.settings.pos.some(o => o === 'link') : false;
  }

  get showPayoutPOS(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings && widget.settings.pos && widget.settings.pos.length > 0 ? widget.settings.pos.some(o => o === 'payout') : false;
  }

  get showRefundPOS(): boolean {
    const widget = this._widgetIndex['wallet.globals']
    return widget && widget.settings && widget.settings.pos && widget.settings.pos.length > 0 ? widget.settings.pos.some(o => o === 'refund') : false;
  }

  @Mutation
  setScreenSize(value: IScreenSize) {
    this._screenSize = value;
  }

  @Mutation
  setCountries(value: Array<ICountry>) {
    this._countries = value;
  }

  @Mutation
  setProducts(value: Array<IProduct>) {
    this._products = value;
  }

  @Mutation
  setWidgets(value: Array<IAppWidget>) {
    const index: Record<string, IAppWidget> = {}
    if (value) {
      value.forEach(w => {
        try {
          w.settings = JSON.parse(w.widgetSettings)
        } catch (err) {
          $ph.error(err)
        }
        index[w.widgetName] = w
      })
    }
    this._widgetIndex = index
    this._widgets = value;

    const dw = value.filter(w => w.widgetType === 'dashboard') || []
    if (this._screenSize === IScreenSize.sm) {
      dw.sort((a, b) => (a.widgetRow - b.widgetRow) !== 0 ? (a.widgetRow - b.widgetRow) : (a.widgetOrderSM - b.widgetOrderSM))
    } else if (this._screenSize === IScreenSize.lg) {
      dw.sort((a, b) => (a.widgetRow - b.widgetRow) !== 0 ? (a.widgetRow - b.widgetRow) : (a.widgetOrderLG - b.widgetOrderLG))
    } else {
      dw.sort((a, b) => (a.widgetRow - b.widgetRow) !== 0 ? (a.widgetRow - b.widgetRow) : (a.widgetOrderMD - b.widgetOrderMD))
    }

    let cr = 0
    let row: IAppWidgetRow = { cols: [] }
    const rows: Array<IAppWidgetRow> = []
    dw.forEach(w => {
      if (cr !== w.widgetRow) {
        row = { cols: [] }
        rows.push(row)
        cr = w.widgetRow
      }
      row.cols.push(w)
    })

    this._dashboardRows = rows

    if (this._widgetIndex['wallet.dashboard.transactions']) {
      const operStyles: Record<string, string> = {}
      const settings: any = this._widgetIndex['wallet.dashboard.transactions'].settings
      if (settings) {
        for (let prop in settings) {
          if (settings.hasOwnProperty(prop)) {
            if (prop !== 'count') {
              if (settings[prop] && Array.isArray(settings[prop])) {
                settings[prop].forEach(item => {
                  operStyles[item] = prop
                });
              }
            }
          }
        }
      }
      this._operationsStyles = operStyles
    }

    if (this._widgetIndex['wallet.dashboard.accounts'] && this._widgetIndex['wallet.dashboard.accounts'].settings) {
      this._accountsSettings = this._widgetIndex['wallet.dashboard.accounts'].settings.types || {}
    }
  }

  @Mutation
  setTableRows(value: number) {
    this._tableRows = value;
  }

  @Mutation
  setReportView(value: boolean) {
    this._reportView = value;
  }

  @Mutation
  updateSystem() {
    this._menu = $ph.clone($ph.appMenu().childs)
    this._languages = $ph.clone($ph.languages())
  }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------
// -- DEFAULTS
// -------------------------------------------------------------------------------------------------------------------------------------------------------

export const TABLE_OPTIONS_DEFAULT: ITableOptions = {
  itemsPerPage: 0,
  page: 1,
  sortBy: [],
  sortDesc: [],
}

export const SEARCH_RESULT_DEFAULT: ISearchResult<any> = {
  total: 0,
  limit: 0,
  items: [],
  node: '',
  offset: 0,
  nodesInfo: [],
}

export const DEF_STRING_ITEM: IStringGlossaryItem = {
  code: '?',
  label: 'system.stateUnknown',
  icon: 'fa-question-circle',
  color: 'disabled',
  textColor: null,
}

export const DEF_NUMBER_ITEM: INumGlossaryItem = {
  code: -1,
  label: 'system.stateUnknown',
  icon: 'fa-question-circle',
  color: 'disabled',
  textColor: null,
}
