import { StringGlossary } from '@/plugins/phoenix/glossaries';
import { DEF_STRING_ITEM } from '../store';
import { EntityStates } from '../types';

const glossary: StringGlossary = new StringGlossary('EntityStates',
  [
    { code: EntityStates.Active, label: 'globals.lists.entities.' + EntityStates.Active, icon: 'fa-check', color: 'success', textColor: null },
    { code: EntityStates.Blocked, label: 'globals.lists.entities.' + EntityStates.Blocked, icon: 'fa-ban', color: 'error', textColor: null },
  ],
  DEF_STRING_ITEM,
);

export default glossary;
